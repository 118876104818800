import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  loginPhonepe,
  loginMagicLink,
  updateLoginState,
} from '../../actions/index';
import { Text, BoldText } from '../../components/common/Text';
import { FadeInTransition } from '../../components/transitions/transitions';
import { PrimaryButton } from '../../components/common/Buttons';
import Modal from '../common/Modal';
import Carousel from '../../components/common/Carousel';
import LoadingComponent from '../../components/common/LoadingComponent';

import heroImage1 from '../../images/OpdCor1.png';
import heroImage2 from '../../images/OpdCor2.png';
import heroImage3 from '../../images/OpdCor3.png';
import { logEvent } from '../../utils/logEvent';
import StarPHCLogo from '../../images/StarPHC.png';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  marginbottom: 120px;
`;

const BannerImageContainer = styled.div`
  width: ${(props) => props.width};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  flex-shrink: 0;
`;

const HeroImage = styled.img`
  width: 100%;
  max-width: 400px;
`;

const TitleText = styled(BoldText)`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  color: #524b72;
  margin-top: 128px;
`;

const SubtitleText = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #706e96;
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: center;
`;

const StyledButtonText = styled(BoldText)`
  color: white;
  text-transform: uppercase;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

const Heading = styled.div`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  color: #524b72;
  margin-top: 34px;
  padding: 0px 47px;
  margin-bottom: 34px;
`;

const SubHeading = styled.div`
  ont-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  color: #827f92;
  padding: 0px 58px;
  margin-bottom: 107px;
`;

const GetStartedButton = styled.div`
  position: absolute;
  bottom: 4%;
  background: #1c63b8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 0px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
`;

class OnboardingHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoadingModal: false,
      rt: '',
    };
  }

  componentDidMount = () => {
    const { dispatch, history, location, loginState } = this.props;
    window.baseLandingUrl = window.location.href;
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    const rt = searchParams.get('rt');
    this.setState({ rt: rt ? rt : '' });
    dispatch({
      type: 'SET_TYPE_OF_USER',
      payload: rt ? rt : '',
    });
    const magicLink = searchParams.get('mluib7c');
    if (magicLink) {
      logEvent('Onboarding Magic Code Applied');
      this.setState({ showLoadingModal: true });
      dispatch(updateLoginState('LOGGED_OUT'));
      dispatch({
        type: 'UNSET_USER_INFO',
      });
      dispatch(loginMagicLink(magicLink, history, searchParams.get('tab')));
    } else if (loginState === 'LOGGED_IN') {
      logEvent('Onboarding User Logged In');
      history.replace('/home');
    } else if (loginState === 'ONE_LAST_STEP') {
      logEvent('Onboarding One Last Step Screen');
      history.push('/one-last-step');
    } else if (loginState === 'SELECT_USER') {
      history.push('/select-user');
    } else {
      // GA Tracking
      window.gtag('event', 'screen_view', {
        screen_name: 'Intro Screen',
      });
      logEvent('Onboarding Intro Screen');
      window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
        .then((sdk) => {
          if (sdk.isMethodSupported('fetchGrantToken')) {
            return sdk.fetchGrantToken().then((res) => {
              this.setState({
                showLoadingModal: true,
              });
              dispatch(loginPhonepe(res.grantToken, history, location.search));
            });
          }
        })
        .catch((err) => {
          // either phonepe SDK does not exist, or something went wrong fetching grant token
          console.log(err);
        });
    }
  };

  onNext = () => {
    window.gtag('event', 'conversion', {
      send_to: 'AW-753807922/L-FUCPC27ZgBELLkuOcC',
    });
    const { history, location } = this.props;
    history.push(`/login${location.search}`);
  };

  render() {
    const { showLoadingModal } = this.state;
    return (
      <FadeInTransition
        in={true}
        timeout={2000}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          {this.state.rt ? (
            <CarouselContainer>
              <Carousel autoplay showDots>
                <BannerImageContainer
                  width={`${Math.min(document.body.clientWidth, 800)}px`}
                >
                  <HeroImage src={heroImage1} />
                  <TitleText>Book a FREE lab test today</TitleText>
                </BannerImageContainer>
                <BannerImageContainer
                  width={`${Math.min(document.body.clientWidth, 800)}px`}
                >
                  <HeroImage src={heroImage2} />
                  <TitleText>Book a FREE In-clinic Consultation</TitleText>
                </BannerImageContainer>
                <BannerImageContainer
                  width={`${Math.min(document.body.clientWidth, 800)}px`}
                >
                  <HeroImage src={heroImage3} />
                  <TitleText>Order Prescription Medicines Online</TitleText>
                </BannerImageContainer>
              </Carousel>
            </CarouselContainer>
          ) : (
            <CarouselContainer>
              <img src={StarPHCLogo} />
              <Heading>Free Preventive Health Checkups (PHCs)</Heading>
              <SubHeading>
                Book your free Preventive Health Checkup (PHC) today
              </SubHeading>
            </CarouselContainer>
          )}

          <GetStartedButton onClick={() => this.onNext()}>
            <StyledButtonText>get started</StyledButtonText>
          </GetStartedButton>
          {showLoadingModal && (
            <Modal>
              <ModalContainer>
                <LoadingComponent color="white" />
              </ModalContainer>
            </Modal>
          )}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(OnboardingHome);
