import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
  overflow-x: hidden;
  width: 100%;
`;

const SlideContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-top: 6px;
`;

const Dot = styled.div`
  width: ${(props) => (props.active ? '12px' : '4px')};
  height: ${(props) => (props.active ? '4px' : '4px')};
  margin: 0 3px;
  border-radius: 4px;
  background-color: ${(props) => (props.active ? '#1C63B8' : '#E3E3E3')};
`;

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIdx: 0,
    };
    this.carouselRef = React.createRef();
  }

  componentDidMount = () => {
    this.setupAutoplay();
  };

  componentWillUnmount() {
    clearInterval(this.autoplayTimeout);
  }

  setupAutoplay() {
    const { autoplay, delay } = this.props;
    if (autoplay) {
      this.currentOffset = 0;
      this.autoplayTimeout = setInterval(this.scrollCarousel, delay || 2000);
    }
  }

  tweenScroll(width, duration) {
    const carouselEle = this.carouselRef.current;
    const offsetPerTimestamp = width / duration;
    const animate = (timestamp) => {
      const progress = timestamp - start;
      carouselEle.style.transform = `translateX(${
        -1 * (this.currentOffset + offsetPerTimestamp * progress)
      }px)`;
      if (progress < duration) {
        requestAnimationFrame(animate);
      } else {
        this.currentOffset += width;
        // Remove any discrepancies
        carouselEle.style.transform = `translateX(${
          -1 * this.currentOffset
        }px)`;
      }
    };
    const start = performance.now();
    requestAnimationFrame(animate);
  }

  tweenToStart(duration) {
    const carouselEle = this.carouselRef.current;
    const offsetPerTimestamp = this.currentOffset / duration;
    const animate = (timestamp) => {
      const progress = timestamp - start;
      carouselEle.style.transform = `translateX(${
        -1 * (this.currentOffset - offsetPerTimestamp * progress)
      }px)`;
      if (progress < duration) {
        requestAnimationFrame(animate);
      } else {
        this.currentOffset = 0;
        // Reset the position
        carouselEle.style.transform = 'translateX(0)';
      }
    };
    const start = performance.now();
    requestAnimationFrame(animate);
  }

  scrollCarousel = () => {
    const { currentIdx } = this.state;
    const { animateDuration } = this.props;
    const carouselEle = this.carouselRef.current;
    const childWidth = carouselEle.children[currentIdx].clientWidth;
    if (childWidth) {
      if (currentIdx === carouselEle.children.length - 1) {
        this.tweenToStart(animateDuration || 500);
        this.setState({
          currentIdx: 0,
        });
      } else {
        this.tweenScroll(childWidth, animateDuration || 500);
        this.setState({
          currentIdx: currentIdx + 1,
        });
      }
    }
  };

  render() {
    const { className, children, showDots } = this.props;
    const { currentIdx } = this.state;
    return (
      <OuterContainer className={className}>
        <SlideContainer ref={this.carouselRef}>{children}</SlideContainer>
        {showDots && (
          <DotContainer>
            {children.map((ele, idx) => (
              <Dot key={idx} active={currentIdx === idx} />
            ))}
          </DotContainer>
        )}
      </OuterContainer>
    );
  }
}

Carousel.propTypes = {
  showDots: PropTypes.bool,
  autoplay: PropTypes.bool,
  delay: PropTypes.number,
  animateDuration: PropTypes.number,
};

export default Carousel;
